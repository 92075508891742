import './languages';

import { Box, IconButton, Text } from '@codecademy/gamut';
import { MiniCopyIcon } from '@codecademy/gamut-icons';
import styled from '@emotion/styled';
import Highlight, {
  defaultProps,
  Language,
  Prism as PrismRR,
} from 'prism-react-renderer';
import Prism from 'prismjs';
import React, { useState } from 'react';

import { codecademyTheme, syntax, ui } from './theme';

type PrismLib = typeof PrismRR & typeof Prism;

export interface ColorizedBlockProps {
  language?: Language;
  copyable?: boolean;
  text: string;
  className?: string;
}

const StyledContainer = styled.pre<{ copyable?: boolean }>`
  padding: 1rem;
  background: #211e2f;
  margin-bottom: 0;
  color: ${syntax.white};
  background-color: ${ui.background};
  font-family: Menlo, Monaco, 'Courier New', monospace;
  font-size: 14px;
  text-align: left;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: break-word;
  word-wrap: normal;
  line-height: 24px;

  ${({ copyable }) => copyable && 'padding-right: 32px;'}
`;

export const ColorizedBlock: React.FC<ColorizedBlockProps> = ({
  language,
  copyable,
  text = '',
  className,
}) => {
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  return language ? (
    <Box position="relative">
      <Highlight
        {...defaultProps}
        code={text}
        theme={codecademyTheme}
        language={language}
        Prism={Prism as PrismLib}
      >
        {({ tokens, getLineProps, getTokenProps }) => (
          <StyledContainer
            role="region"
            aria-label="code snippet"
            data-testid="colorized-block"
            className={className}
            copyable
          >
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </StyledContainer>
        )}
      </Highlight>
      {copyable ? (
        <Box
          position="absolute"
          top={6}
          right={12}
          onMouseLeave={() => setShowCopySuccess(false)}
          onBlur={() => setShowCopySuccess(false)}
        >
          <IconButton
            icon={MiniCopyIcon}
            size="small"
            mode="dark"
            tip={showCopySuccess ? 'Code copied!' : 'Copy to clipboard'}
            tipProps={{ placement: 'floating' }}
            onClick={async () => {
              await navigator.clipboard.writeText(text);
              setShowCopySuccess(true);
            }}
          />
        </Box>
      ) : null}
    </Box>
  ) : (
    <Text as="code">{text}</Text>
  );
};
